import React from "react"
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Live Video Interviewing</p>
          </span>
        ),
        className: "banner5-title",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Built for Recruiting</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Don't make your job seekers download an app.&nbsp;
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
          className: "banner5-button",
          type: "primary",
          children: (
            <span>
              <span>
                <p>Start Free Now</p>
              </span>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: "banner5-image kcxgi7x5k3m-editor_css",
    children:
      "https://deephire.s3.amazonaws.com/misc/mikey-vid-interview-landing.png",
  },
}
export const Feature70DataSource = {
  wrapper: { className: "home-page-wrapper feature7-wrapper" },
  page: { className: "home-page feature7" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "feature7-title-wrapper",
    children: [
      {
        name: "title",
        className: "feature7-title-h1",
        children: (
          <span>
            <span>
              <span>
                <p>Why should you use DeepHire over Zoom?</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: "feature7-block-wrapper",
    gutter: 24,
    children: [
      {
        md: 24,
        xs: 24,
        name: "block0",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/check-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <p>A Live Interview Software Built for Recruiting</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            DeepHire live video interviewing was built
                            specifically for recruitment. By using a system that
                            was directly built for recruiters, you will
                            experience a smooth process &amp; will not have to
                            fight with technology to get it to work the way you
                            need it to.&nbsp;
                            <br />
                            <br />
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kcuo7i6etro",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/check-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Record Live Interviews for Sharing</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Live interviews are automatically recorded. Say
                            goodbye to having to memorize everything about the
                            interview. You can share the recorded interviews
                            with your clients or your team.&nbsp;
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kcuobug318m",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/check-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>Add Bookmarks to Your Interviews</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Add bookmarks to the relevant parts of your
                          interviews. Your clients will be able to jump straight
                          to the bookmarked section, saving them time.&nbsp;
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kcuo7jb7fsa",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/check-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <p>Add Documents to Your Interviews</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            Add the candidates resume &amp; test results
                            directly into the interview. This makes it easy to
                            have all the info you need to conduct a successful
                            interview in one place. When you share the interview
                            recording, all documents are shared as well.&nbsp;
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kcuobo7jcmo",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/check-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Schedule Send out Interviews for Your Clients</p>
                        <p>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <p>
                      Impress your clients by using DeepHire to host your send
                      out interviews. The client &amp; candidate will be able to
                      join the interview with 1 click. The interview room will
                      have your branding. You will receive a recording of the
                      send out interview once it is completed, letting you know
                      how to better prepare candidates in the future.&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 24,
        xs: 24,
        name: "block~kd32oyiaeoe",
        className: "feature7-block",
        children: {
          className: "feature7-block-group",
          children: [
            {
              name: "image",
              className: "feature7-block-image",
              children:
                "https://raw.githubusercontent.com/ant-design/ant-design-icons/master/packages/icons-svg/svg/twotone/check-circle.svg",
            },
            {
              name: "title",
              className: "feature7-block-title",
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <p>Your Own Branded Experience</p>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              className: "feature7-block-content",
              children: (
                <span>
                  <span>
                    <p>
                      Uses your logo &amp; branding to highlight your company to
                      both candidates &amp; clients.&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper kd34vl3xcw6-editor_css",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>Enhance your interview experience with Deephrie</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: "title-h1",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <span>
              <p>Start Free Now</p>
            </span>
          </span>
        ),
      },
    },
  },
}
